const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:9091';

export const doApiRequest = async (
  path: string,
  method: 'GET' | 'POST',
  bearerToken?: string | null,
) => {
  const url = `${API_URL}${path}`;

  const headers: HeadersInit = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (bearerToken) {
    headers.Authorization = `Bearer ${bearerToken}`;
  }

  const response = await fetch(url, {
    method,
    headers,
  });

  return response.json();
};
