import React, { createContext, useEffect, useReducer, useMemo } from 'react';

export type ContextProps = {
  // eslint-disable-next-line no-unused-vars
  signIn: (_data: { username: string; password: string }) => Promise<{
    success: boolean;
    message: string;
  }>;
  signOut: () => void;
  // eslint-disable-next-line no-unused-vars
  getUser: (_token: string) => Promise<{
    success: boolean;
    message: string;
  }>;
  authState: {
    isLoggedIn: boolean;
    isLoading: boolean;
    isSignout: boolean;
    userToken: string | null;
    user: any | null;
  };
  user: any | null;
};

const removeTokenFromUrl = () => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.delete('token');
  window.history.replaceState(
    {},
    document.title,
    `${window.location.pathname}${
      urlParams.toString() ? `?${urlParams.toString()}` : ''
    }`,
  );
};

export const AuthContext = createContext<ContextProps>({
  signIn: async () => {
    return { success: false, message: 'Not implemented' };
  },
  signOut: () => null,
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  getUser: async (_token: string) => {
    return { success: false, message: 'Not implemented' };
  },
  authState: {
    isLoggedIn: false,
    isLoading: true,
    isSignout: false,
    userToken: null,
    user: null,
  },
  user: null,
});

const isOkRequest = (response: any) => {
  if (response.ok) {
    return response.json();
  }
  // Try to get error message from response
  return response.json().then((data: any) => {
    throw new Error(data.message);
  });
};

export function AuthProvider({ children }: any) {
  const [authState, dispatch] = useReducer(
    (prevState: any, action: any) => {
      switch (action.type) {
        case 'RESTORE_TOKEN':
          return {
            ...prevState,
            userToken: action.token,
            isLoading: false,
            user: action.user || prevState.user,
          };
        case 'SIGN_IN':
          return {
            ...prevState,
            isSignout: false,
            userToken: action.token || prevState.userToken,
            user: action.user || prevState.user,
            isLoggedIn: true,
          };
        case 'SIGN_OUT':
          return {
            ...prevState,
            isSignout: true,
            userToken: null,
            user: null,
            isLoggedIn: false,
          };
        default:
          return { ...prevState };
      }
    },
    {
      isLoading: true,
      isSignout: false,
      userToken: null,
      user: null,
    },
  );

  useEffect(() => {
    // Fetch the token from storage then navigate to our appropriate place
    const bootstrapAsync = async () => {
      let userToken: string | null = null;

      try {
        userToken = await localStorage.getItem('properties.hoas.fi.userToken');
      } catch (e) {
        console.log(e);
      }

      // Check if token is given in URL
      if (!userToken) {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
          userToken = token;
        }
      }

      dispatch({ type: 'RESTORE_TOKEN', token: userToken });
    };

    bootstrapAsync();
  }, []);

  const authContext = useMemo(
    () => ({
      signIn: async ({
        username,
        password,
      }: {
        username: string;
        password: string;
      }) => {
        return fetch(`${process.env.REACT_APP_API_URL}/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            password,
          }),
        })
          .then((response) => response.json())
          .then(async (data) => {
            console.log('user', data);
            if (data.success && data.data.token) {
              await localStorage.setItem(
                'properties.hoas.fi.userToken',
                data.data.token,
              );
              dispatch({ type: 'SIGN_IN', token: data.data.token });

              return {
                success: true,
                message: 'log-in-success',
              };
            }
            return {
              success: false,
              message: `API-${data.error || 'unknown-error-alert'}`,
            };
          })
          .catch((e) => {
            return {
              success: false,
              message: e.message,
            };
          });
      },
      signOut: async () => {
        await localStorage.removeItem('properties.hoas.fi.userToken');
        dispatch({ type: 'SIGN_OUT', token: null, user: null });
      },
      getUser: async (token: any) => {
        return fetch(`${process.env.REACT_APP_API_URL}/user`, {
          method: 'GET',
          headers: { authorization: `Bearer ${token || authState.userToken}` },
        })
          .then(isOkRequest)
          .then(async (data) => {
            // Remove token from URL
            removeTokenFromUrl();
            if (data.success) {
              const currentToken = await localStorage.getItem(
                'properties.hoas.fi.userToken',
              );

              // Check if localstorage token is same as token from API
              if (authState.userToken !== currentToken) {
                localStorage.setItem(
                  'properties.hoas.fi.userToken',
                  authState.userToken,
                );
              }

              dispatch({ type: 'SIGN_IN', user: data.data });
              return {
                success: true,
                message: '',
              };
            }

            throw new Error(data.message);
          })
          .catch((e) => {
            authContext.signOut();
            console.error(e);
            return {
              success: false,
              message: `API-${e.message || 'unknown-error-alert'}`,
            };
          });
      },
      authState,
      user: authState.user,
    }),
    [authState],
  );

  // Fetch user if userToken set and user null
  useEffect(() => {
    if (authState.user === null && authState.userToken !== null) {
      authContext.getUser(authState.userToken);
    }
  }, [authContext, authState]);

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
}

export default AuthProvider;

export function useAuth() {
  return React.useContext(AuthContext);
}
