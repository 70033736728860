import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useColorModeValue,
  Icon,
  HStack,
  Text,
  Box,
  List,
  ListItem,
  Button,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useAppContext } from '../../../context/App';
import { useTranslation } from '../../../context/Translations';
import { PropertyType } from '../../../types';
import { CloseIcon, SearchIcon } from '../../Icon';

type SearchItem = PropertyType;

function SearchResultItem({
  onClick,
  result,
}: {
  onClick: (searchItem: SearchItem) => void;
  result: SearchItem;
}) {
  const bg = useColorModeValue('white', 'customGray.800');
  const fontColor = useColorModeValue('customGray.800', 'white');
  const searchResultStyle = {
    borderBottom: '1px solid',
    borderColor: '#c7c7c7',
    borderRadius: 'none',
    bg: bg,
    color: fontColor,
    w: '100%',
    padding: '0.75rem 1.5rem',
  };

  return (
    <ListItem>
      <HStack key={result.name} role="menuitem" title={result.name}>
        <Button
          sx={searchResultStyle}
          variant="link"
          _hover={{ textDecoration: 'none' }}
          onClick={() => onClick(result)}
        >
          <HStack w="100%">
            <Text
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              align="left"
              w="60"
            >
              {result.name}
            </Text>
          </HStack>
        </Button>
      </HStack>
    </ListItem>
  );
}

function SearchResults({ itemOnClick, results, query }: any) {
  const { translate } = useTranslation();
  const bg = useColorModeValue('white', 'customGray.800');
  if (!query.length && !results.length) {
    return null;
  }
  const searchResultBoxStyle = {
    borderBottom: '1px',
    borderColor: '#c7c7c7',
    boxShadow: 'xl',
    marginTop: '1',
    position: 'absolute',
    right: '0',
    zIndex: '2',
    maxH: '80vh',
    w: '100%',
    overflowY: 'auto',
    bg: bg,
  };

  if (query.length > 0 && !results.length) {
    return (
      <Box sx={searchResultBoxStyle}>
        <Box
          borderBottom="1px"
          borderColor="#d8d8d8"
          color="#959595"
          fontSize="16"
          p=".75rem 1.5rem"
        >
          {translate('no-search-results')}
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={searchResultBoxStyle}>
      <Text
        width="100%"
        borderBottom="1px"
        borderTop="1px"
        borderColor="customGray.40"
        p="3"
      >
        {translate('search-results-title')}
      </Text>
      <List maxH="80vh" overflow="scroll">
        {results.map((result: SearchItem) => (
          <SearchResultItem
            key={result.id}
            onClick={itemOnClick}
            result={result}
          />
        ))}
      </List>
    </Box>
  );
}

function SearchInput({
  onSearch,
  query,
}: {
  onSearch: (q: string) => void;
  query: string;
}) {
  const { translate } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const handleSearch = (
    e: React.MouseEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>,
  ) => {
    onSearch((e.target as HTMLInputElement).value);
  };

  const clearSearch = () => {
    onSearch('');
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };
  const bg = useColorModeValue('white', 'customGray.800');
  const searchButtonStyle = {
    position: 'absolute',
    padding: '0.8em',
    h: '100%',
    w: '14',
    right: '0',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '0.5',
    bg: 'green.500',
  };

  return (
    <Box>
      <InputGroup h="56px">
        <Input
          bg={bg}
          ref={inputRef}
          id="search-input"
          onClick={(e) => handleSearch(e)}
          onChange={(e) => handleSearch(e)}
          value={query}
          placeholder={translate('search-placeholder')}
          border="1px"
          borderColor="customGray.600"
          p=".75rem"
          pr="2.25rem"
          h="100%"
          _focusVisible={{ outline: 'none', boxShadow: '0 0 0 2px #008151' }}
        />
        <InputRightElement margin="0px" h="100%" width="4.5rem">
          {query.length === 0 ? (
            <Button sx={searchButtonStyle}>
              <Icon color="white" h="7" w="7" as={SearchIcon} />
            </Button>
          ) : (
            <Button onClick={clearSearch} sx={searchButtonStyle}>
              <Icon color="#fff" h="7" w="7" as={CloseIcon} />
            </Button>
          )}
        </InputRightElement>
      </InputGroup>
    </Box>
  );
}

function Search() {
  const { state } = useAppContext();
  const [results, setResults] = useState<PropertyType[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [resultsOpen, setResultsOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onSearch = (q: string) => {
    setSearchQuery(q);
    if (q.length) {
      // very simple search
      const searchResults = state.properties.filter(
        ({ name, nameEn, nameSv, city, region }) =>
          city.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
          region.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
          name.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
          name.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
          nameEn.toUpperCase().indexOf(q.toUpperCase()) > -1 ||
          nameSv.toUpperCase().indexOf(q.toUpperCase()) > -1,
      );

      setResults(searchResults || []);
    }
    setResultsOpen(q.length > 2);
  };

  useEffect(() => {
    function handleClickOutside(event: any): void {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        resultsOpen
      ) {
        setResultsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, resultsOpen]);

  const itemOnClick = (result: SearchItem) => {
    setResultsOpen(false);
    if (state.map) {
      // console.log(state.map.getLayer())
      state.map.getMap().fire('click', {
        propertyTargetId: result.id,
        originalEvent: {},
      });
    }
  };
  const bg = useColorModeValue('white', 'customGray.800');

  return (
    <Box
      p="5"
      position="relative"
      width="100%"
      borderBottom="1px"
      borderColor="customGray.30"
      ref={wrapperRef}
      bg={bg}
    >
      <SearchInput query={searchQuery} onSearch={onSearch} />
      {resultsOpen ? (
        <SearchResults
          itemOnClick={itemOnClick}
          results={results}
          query={searchQuery}
        />
      ) : null}
    </Box>
  );
}

export default Search;
