/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useEffect, useState } from 'react';
import Map, {
  MapProvider,
  useMap,
  NavigationControl,
  ScaleControl,
  MapRef,
} from 'react-map-gl';
import { useParams, useSearchParams } from 'react-router-dom';

import MapboxGl from 'mapbox-gl';

import './Map.scss';
import MapStyleSelect from '../MapStyleSelect';
import { useAppContext } from '../../context/App';
import PropertiesLayer from './Layers/PropertiesLayer';
import Loader from '../Loaders';
import SingleProperty from './Layers/SingleProperty';
import { ImagesLoader } from './ImagesLoader';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
MapboxGl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MapDefaultState: any = {
  minZoom: 10,
  maxZoom: 50,
  hash: true,
  attributionControl: false,
  center: [24.9305, 60.2418],
  maxBounds: [
    [24.4644, 60.0171],
    [25.3979, 60.4465],
  ],
  mapStyle:
    localStorage.getItem('layerStyle') || 'mapbox://styles/mapbox/light-v9',
  mapboxAccessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || '',
};

function MapRouteHandler({
  setLoader,
}: {
  setLoader: (state: boolean) => void;
}) {
  const {
    state,
    dispatch,
    fetchProperty,
    selectProperty,
    fetchParkingSpaces,
    selectSpace,
    selectBuilding,
  } = useAppContext();
  const { propertySlug, buildingSlug, spaceSlug } = useParams<
    'propertySlug' | 'buildingSlug' | 'spaceSlug'
  >();
  const [searchParams] = useSearchParams();
  const { mainMap } = useMap();

  const getProperty = async (slug: string) => {
    setLoader(true);
    try {
      await fetchProperty(+slug);
    } finally {
      setLoader(false);
    }
  };

  const getParkingSpaces = async (slug: string) => {
    setLoader(true);
    try {
      await fetchParkingSpaces(+slug);
    } finally {
      setLoader(false);
    }
  };

  // Listen propertySlug param changes
  useEffect(() => {
    if (propertySlug) {
      if (+propertySlug !== state.selectedProperty?.id) {
        getProperty(propertySlug);
      }
    } else if (state.selectedProperty?.id && !propertySlug && mainMap) {
      setTimeout(() => {
        mainMap.easeTo({
          center: MapDefaultState.center,
          zoom: 11,
        });
      }, 250);
      mainMap.once('moveend', () => selectProperty(null));
    }
  }, [propertySlug]);

  // Listen buildingSlug param changes
  useEffect(() => {
    if (state.selectedProperty && buildingSlug) {
      const found = state.selectedProperty?.buildings?.find(
        (b) => b.id === +buildingSlug,
      );
      selectBuilding(found);
    } else if (state.selectedProperty && !buildingSlug) {
      selectBuilding(null);
    }
  }, [buildingSlug, state.selectedProperty]);

  // Listen spaceSlug param changes
  useEffect(() => {
    if (state.selectedBuilding && spaceSlug) {
      const found = state.selectedBuilding?.spaces?.find(
        (s) => s.id === spaceSlug,
      );
      selectSpace(found);
    } else if (state.selectedBuilding && !spaceSlug) {
      selectSpace(null);
    } else if (buildingSlug === 'area' && spaceSlug) {
      const found = state.selectedProperty?.areas?.find(
        (a) => a.id === spaceSlug,
      );

      selectSpace(found);
    } else if (!buildingSlug && !spaceSlug) {
      selectSpace(null);
    }
  }, [spaceSlug, state.selectedBuilding]);

  // Listen showParking searchparam changes
  useEffect(() => {
    if (propertySlug) {
      if (searchParams.get('showParking') !== null) {
        getParkingSpaces(propertySlug);
      } else if (
        searchParams.get('showParking') === null &&
        state.parkingSpaces.length
      ) {
        dispatch({ type: 'ADD_PARKING_SPACES', payload: null });
      }
    }
  }, [searchParams.get('showParking'), propertySlug]);

  return null;
}

function MainMap() {
  const { state, dispatch } = useAppContext();
  const [cursor, setCursor] = useState<string>('auto');
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [showPropertyLoader, setShowProperyLoader] = useState<boolean>(false);

  const mapRef = React.useRef<MapRef>(null);

  const [viewState, setViewState] = React.useState(MapDefaultState);

  const onMouseEnter = React.useCallback(() => setCursor('pointer'), []);
  const onMouseLeave = React.useCallback(() => {
    setCursor('auto');
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      dispatch({
        type: 'SET_MAP',
        payload: mapRef.current,
      });
    }
  }, [mapRef.current]);

  useEffect(() => {
    if (state.mainMenuOpen) {
      document.body.classList.add('menu-open');
    } else {
      document.body.classList.remove('menu-open');
    }
    setTimeout(() => {
      mapRef.current?.resize();
    }, 250);
  }, [state.mainMenuOpen]);

  if (!state.properties || !state.properties.length) {
    return <Loader />;
  }

  return (
    <MapProvider>
      <Map
        ref={mapRef}
        id="mainMap"
        {...viewState}
        initialViewState={{
          bounds: MapDefaultState.maxBounds,
          latitude: MapDefaultState.center[1],
          longitude: MapDefaultState.center[0],
        }}
        cursor={cursor}
        style={{
          // width: state.mainMenuOpen ? '80vw' : '100vw',
          // height: '100vh',
          cursor: 'pointer',
        }}
        className={state.mainMenuOpen ? 'menu-open' : ''}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onLoad={() => setMapLoaded(true)}
      >
        <ImagesLoader />
        <MapRouteHandler setLoader={setShowProperyLoader} />
        <MapStyleSelect />
        <ScaleControl maxWidth={80} />
        <NavigationControl position="bottom-right" showCompass visualizePitch />

        {showPropertyLoader && <Loader />}

        {mapLoaded ? (
          <>
            {state.selectedProperty ? (
              <SingleProperty property={state.selectedProperty} />
            ) : null}

            <PropertiesLayer />
          </>
        ) : (
          <Loader />
        )}
      </Map>
    </MapProvider>
  );
}

export default MainMap;
