import centroid from '@turf/centroid';
import React, { useEffect } from 'react';
import { Text, Box } from '@chakra-ui/react';
import {
  Source,
  Layer,
  FillLayer,
  useMap,
  SymbolLayer,
  Popup,
} from 'react-map-gl';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../../context/App';
import { useTranslation } from '../../../context/Translations';

const SOURCE_ID = 'areas-data';
const SOURCE_ID_SYMBOLS = 'areas-data-symbols';

const LAYER_ID = 'areas';
const LAYER_ID_SYMBOLS = 'areas-symbols';

const COLOR_SELECTED = '#078571';
const layerStyleAreas: FillLayer = {
  id: LAYER_ID,
  type: 'fill',
  layout: {
    'fill-sort-key': 0,
  },
  paint: {
    'fill-color': '#078571',
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      0.75,
      ['boolean', ['feature-state', 'selected'], false],
      0.75,
      0.5,
    ],
  },
};

const layerStyleSpacesSymbols: SymbolLayer = {
  id: LAYER_ID_SYMBOLS,
  type: 'symbol',
  layout: {
    'symbol-sort-key': 200,
    'text-field': ['get', 'name'],
    'text-radial-offset': 0.5,
    'text-justify': 'center',
  },
  paint: {
    'text-halo-blur': 0.1,
    'text-color': '#FFFFFF',
    'text-halo-width': 0.1,
    'text-halo-color': '#222222',
  },
};

function ToolTip({ tooltipInfo }: any) {
  return (
    <Box
      position="absolute"
      m="2"
      p="2"
      background="black"
      color="white"
      maxWidth="72"
      fontSize="18"
      zIndex="9"
      pointerEvents="none"
      left={tooltipInfo.x}
      top={tooltipInfo.y}
    >
      <Text>{tooltipInfo.feature.properties.name}</Text>
    </Box>
  );
}

function SelectedAreaPopup() {
  const { state } = useAppContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { translate } = useTranslation();

  if (!state.selectedArea) {
    return null;
  }

  const feature = centroid(state.selectedArea.geo, {
    properties: {
      ...state.selectedArea,
    },
  });

  return (
    <Popup
      maxWidth="20"
      className="selected-parking-space-popup"
      closeOnClick
      closeButton
      offset={20}
      onClose={() => {
        const params = new URLSearchParams(window.location.search);
        params.delete('selectedArea');
        navigate({
          pathname: window.location.pathname,
          search: params.toString(),
        });
      }}
      latitude={feature.geometry.coordinates[1]}
      longitude={feature.geometry.coordinates[0]}
    >
      <div className="popup-header">
        <b>{feature.properties?.name}</b> ({feature.properties.id})
      </div>
    </Popup>
  );
}

let selectedId: any = null;
function Areas() {
  const { mainMap } = useMap();
  const { state, selectArea } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [hoverInfo, setHoverInfo] = React.useState<any>(null);
  // const [searchParams, setSearchParams] = useSearchParams();
  // const filters = searchParams.getAll('filters[]');

  const geojsonAreas: any = React.useMemo(
    () => ({
      type: 'FeatureCollection',
      features: state.selectedProperty?.areas?.map((area) => ({
        type: 'Feature',
        id: +area.fid,
        geometry: area.geo,
        properties: { ...area },
      })),
    }),
    [state.selectedProperty],
  );

  const geojsonSymbols: any = React.useMemo(
    () => ({
      type: 'FeatureCollection',
      features: geojsonAreas.features.map((f: any) =>
        centroid(f, { properties: f.properties }),
      ),
    }),
    [geojsonAreas],
  );

  const onClick = (
    e: mapboxgl.MapMouseEvent & {
      features?: any;
    } & mapboxgl.EventData,
  ) => {
    const params = new URLSearchParams(window.location.search);
    const feature = e.features && e.features[0];
    const featuresClicked = mainMap?.queryRenderedFeatures(e.point);
    if (
      params.get('showParking') !== null &&
      feature &&
      featuresClicked?.length === 1
    ) {
      params.set('selectedArea', feature.properties.id);
      navigate({
        pathname: window.location.pathname,
        search: params.toString(),
      });
    }
  };

  const onHover = React.useCallback((event) => {
    const {
      features,
      point: { x, y },
    } = event;
    const hoveredFeature = features && features[0];
    // setHoverFeatureState(hoveredFeature.id);

    setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
  }, []);

  const onMouseLeave = () => {
    // setHoverFeatureState(null);
    setHoverInfo(null);
  };

  const setSelectedFeatureState = (id: number | null) => {
    // un-hilight current
    if (selectedId !== null) {
      mainMap?.setFeatureState(
        { source: SOURCE_ID, id: selectedId },
        { selected: false },
      );

      selectedId = null;
    }

    // if feature id provided. set feature state
    if (id) {
      selectedId = id;
      mainMap?.setFeatureState(
        { source: SOURCE_ID, id: selectedId },
        { selected: true },
      );
    }
  };

  useEffect(() => {
    setSelectedFeatureState(state.selectedArea?.fid || null);
  }, [state.selectedArea]);

  useEffect(() => {
    if (searchParams.get('selectedArea')) {
      const found = state.selectedProperty?.areas?.find(
        (p) => p.id === searchParams.get('selectedArea'),
      );
      selectArea(found || null);
    } else {
      selectArea(null);
    }
  }, [searchParams.get('selectedArea')]);

  useEffect(() => {
    mainMap?.on('mousemove', LAYER_ID, onHover);
    mainMap?.on('mouseleave', LAYER_ID, onMouseLeave);
    mainMap?.on('click', LAYER_ID, onClick);
    return () => {
      mainMap?.off('mousemove', LAYER_ID, onHover);
      mainMap?.off('mouseleave', LAYER_ID, onMouseLeave);
      mainMap?.off('click', LAYER_ID, onClick);
    };
  }, [location.pathname]);

  return (
    <>
      <Source id={SOURCE_ID} type="geojson" data={geojsonAreas}>
        <Layer {...layerStyleAreas} />
      </Source>
      {/* <Source id={SOURCE_ID_SYMBOLS} type="geojson" data={geojsonSymbols}>
        <Layer {...layerStyleSpacesSymbols} filter={filter} />
      </Source> */}
      <SelectedAreaPopup />
      {hoverInfo && <ToolTip tooltipInfo={hoverInfo} />}
    </>
  );
}

export default Areas;
