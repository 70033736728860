import { useMap } from 'react-map-gl';
import { loadImage } from './utils';

export function ImagesLoader() {
  const { mainMap } = useMap();
  // Disable warn
  const consoleWarn = console.warn;
  console.warn = () => ({});

  // Add other missing images
  mainMap?.on('styleimagemissing', ({ id }: { id: string }) => {
    if (mainMap) {
      loadImage(id, mainMap);
    }
  });

  mainMap?.once('idle', () => {
    console.warn = consoleWarn;
  });

  return null;
}
