import React, { useEffect, useState } from 'react';

import { useMap, Popup, Marker, Layer, Source, FillLayer } from 'react-map-gl';
import { isMobile } from 'react-device-detect';
import {
  useColorModeValue,
  Text,
  VStack,
  IconButton,
  Box,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../../context/App';
import { useTranslation } from '../../../context/Translations';
import { PropertyType } from '../../../types';
import { getPolygonBounds } from '../../../utils';
import { PinIcon } from '../../Icon';

const layerStyleProperties: FillLayer = {
  id: 'properties',
  type: 'fill',
  paint: {
    'fill-color': '#5865F6',
    'fill-opacity': 0.3,
  },
};

function Pin({
  className = '',
  onClick,
  onMouseOver,
  onMouseLeave,
}: {
  className?: string;
  onClick?: (e: any) => void;
  onMouseOver?: (e: any) => void;
  onMouseLeave?: (e: any) => void;
}) {
  return (
    <IconButton
      aria-label="Open property"
      colorScheme="Black"
      className={`property-map-marker ${className}`}
      variant="link"
      icon={<PinIcon color="#078571" />}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    />
  );
}

function PropertiesLayer() {
  const { mainMap } = useMap();
  const { state, preSelectProperty } = useAppContext();
  const bg = useColorModeValue('white', 'gray.900');

  const [hoverInfo, setHoverInfo] = useState<any>(null);
  const [popupInfo, setPopupInfo] = useState<any>(null);
  const { translate } = useTranslation();

  const geojsonProperties: any = React.useMemo(
    () => ({
      type: 'FeatureCollection',
      features: state.properties.map((property) => ({
        type: 'Feature',
        geometry: property.geo,
        properties: { ...property },
      })),
    }),
    [state.properties],
  );

  // Add event listeners
  useEffect(() => {
    const handleMapOnClick = (e: any) => {
      if (e.propertyTargetId) {
        const property = state.properties.find(
          ({ id }) => id === e.propertyTargetId,
        );

        if (property) {
          onClick({
            property,
            lat: property.centroid.coordinates[1],
            lng: property.centroid.coordinates[0],
          });
        }
      }
    };
    mainMap?.on('click', handleMapOnClick);

    return () => {
      mainMap?.off('click', handleMapOnClick);
    };
  }, []);

  const onMouseOver = React.useCallback(
    (event) => {
      const { property, x, y } = event;
      if (!isMobile) {
        setHoverInfo(
          property !== state.selectedProperty?.id
            ? { feature: property, x, y }
            : null,
        );
      }
    },
    [state.selectedProperty],
  );

  const onClick = React.useCallback(
    (event) => {
      const { property, lat, lng } = event;

      // Zoom to bounds
      const bounds = getPolygonBounds(property.geo);
      mainMap?.fitBounds(bounds, { maxZoom: Math.max(mainMap?.getZoom(), 12) });

      setPopupInfo(
        property !== state.selectedProperty?.id
          ? { feature: property, lat, lng }
          : null,
      );

      preSelectProperty(property);
    },
    [state.selectedProperty],
  );

  const onMouseLeave = React.useCallback(() => {
    setHoverInfo(null);
  }, []);

  const closePopup = () => {
    setPopupInfo(null);
  };

  const filter = React.useMemo(
    () => ['==', 'id', popupInfo ? popupInfo?.feature?.id : false],
    [popupInfo],
  );

  const popupOnClose = () => {
    preSelectProperty(null);
    setPopupInfo(null);
  };

  if (!mainMap) {
    return null;
  }

  return (
    <>
      <Source id="properties-data" type="geojson" data={geojsonProperties}>
        <Layer {...layerStyleProperties} filter={filter} />
      </Source>
      {state.properties
        .filter((p) => p.id !== state.selectedProperty?.id)
        .map((property) => (
          <Marker
            key={`marker-${property.id}`}
            longitude={property.centroid.coordinates[0]}
            latitude={property.centroid.coordinates[1]}
            anchor="bottom"
          >
            <Pin
              className={`${
                popupInfo?.feature.id === property.id
                  ? 'property-map-marker--selected'
                  : ''
              } ${
                popupInfo && popupInfo?.feature.id !== property.id
                  ? 'property-map-marker--notselected'
                  : ''
              }`}
              onMouseOver={(e) => {
                onMouseOver({ property, x: e.clientX, y: e.clientY });
              }}
              onMouseLeave={onMouseLeave}
              onClick={() =>
                onClick({
                  property,
                  lat: property.centroid.coordinates[1],
                  lng: property.centroid.coordinates[0],
                })
              }
            />
          </Marker>
        ))}

      {hoverInfo && (
        <Box
          position="fixed"
          m="2"
          p="2"
          background="black"
          color="white"
          maxWidth="72"
          fontSize="14"
          zIndex="9"
          pointerEvents="none"
          left={hoverInfo.x}
          top={hoverInfo.y}
        >
          <Text>{hoverInfo.feature.name}</Text>
          <Box>
            <Text>
              {hoverInfo.feature.region}, {hoverInfo.feature.city}
            </Text>
          </Box>
        </Box>
      )}

      {popupInfo && (
        <Popup
          anchor="top"
          longitude={Number(popupInfo.lng)}
          latitude={Number(popupInfo.lat)}
          closeOnClick={false}
          closeButton
          style={{ maxWidth: '50rem' }}
          onClose={popupOnClose}
        >
          <VStack
            boxShadow="lg"
            bg={bg}
            p="4"
            spacing="2"
            w="100%"
            alignItems="flex-start"
            fontSize="15"
          >
            <Box mb="2" alignItems="baseline">
              <Box mb="2">
                <Text fontSize="18" as="b">
                  {popupInfo.feature.name}
                </Text>
              </Box>
              <Text>
                {popupInfo.feature.region}, {popupInfo.feature.city}{' '}
              </Text>
              <Text marginTop="0">KPID {popupInfo.feature.id}</Text>
            </Box>
            <Link
              onClick={() => setPopupInfo(null)}
              to={`/${popupInfo.feature.id}`}
              className="green-button"
            >
              {translate('show-property')}
            </Link>
          </VStack>
        </Popup>
      )}
    </>
  );
}

export default PropertiesLayer;
