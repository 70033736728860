import React from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  useColorModeValue,
  Icon,
  Box,
  Text,
  Button,
  HStack,
} from '@chakra-ui/react';
import { useAuth } from '../../context/Auth';
import { useTranslation } from '../../context/Translations';
import { LogoutIcon, ProfileIcon } from '../Icon';

function BackToHomeButton() {
  const { propertySlug } = useParams<'propertySlug'>();
  const { translate } = useTranslation();
  const background = useColorModeValue('white', 'black');

  if (!propertySlug) {
    return null;
  }

  return (
    <Box mt={{ md: '4', lg: '0' }}>
      <Button
        bg={background}
        h="12"
        shadow="xl"
        color="black"
        as={Link}
        to="/"
        _hover={{ bg: background }}
        _focus={{ bg: background }}
      >
        {translate('reset-all')}
      </Button>
    </Box>
  );
}

function LogoutButton({
  onClick,
  text,
}: {
  onClick: () => void;
  text: string;
}) {
  const background = useColorModeValue('white', 'black');
  return (
    <Button
      bg={background}
      h="12"
      color="black"
      _hover={{ bg: background }}
      _focus={{ bg: background }}
      onClick={onClick}
      p="0"
    >
      <Text mr="2">{text}</Text>
      <Icon h="6" w="6" as={LogoutIcon} />
    </Button>
  );
}

function ProfileBar() {
  const { translate } = useTranslation();
  const { signOut, authState } = useAuth();
  const background = useColorModeValue('white', 'black');
  const fontText = useColorModeValue('black', 'white');

  if (!authState.userToken) {
    return null;
  }

  return (
    <HStack
      position="fixed"
      top="1rem"
      right="1rem"
      display="flex"
      zIndex="999"
      flexFlow={{ md: 'column-reverse', lg: 'row' }}
      alignItems={{ md: 'end', lg: 'center' }}
    >
      <BackToHomeButton />

      {!authState.user?.id ? null : (
        <HStack
          ml="4"
          bg={background}
          h="12"
          shadow="xl"
          color="black"
          pl="4"
          pr="4"
          borderRadius="5"
        >
          <HStack mr="3">
            <Icon color={fontText} h="6" w="6" as={ProfileIcon} />
            <Text color={fontText}>{authState.user?.email}</Text>
          </HStack>
          <Box>
            <LogoutButton onClick={signOut} text={translate('logout')} />
          </Box>
        </HStack>
      )}
    </HStack>
  );
}

export default ProfileBar;
