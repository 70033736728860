import React, { useEffect, useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { LngLatLike } from 'mapbox-gl';
import {
  useColorModeValue,
  Icon,
  Stack,
  Box,
  Text,
  Button,
  List,
  ListItem,
  HStack,
} from '@chakra-ui/react';

import SpacesFilters from './Filters';
import Search from './Search';
import { useAppContext } from '../../context/App';

import { BuildingType, PropertyType } from '../../types';
import { useTranslation } from '../../context/Translations';

// import './Menu.scss';

import { MenuCloseIcon, PinIcon, ReturnIcon, Hamburger } from '../Icon';
import { useAuth } from '../../context/Auth';

function MenuHeader() {
  const { state } = useAppContext();
  const { translate } = useTranslation();
  const bg = useColorModeValue('#F6F7F7', 'customGray.800');
  return (
    <Box
      py="1.5rem"
      pr="0"
      pl="5.5rem"
      display="flex"
      width="100%"
      borderBottom="1px solid #e0e0e0"
      bg={bg}
      h="16"
      lineHeight="1"
    >
      {state.selectedProperty ? (
        <Box>
          <HStack>
            <Text as="b">{translate('menu-title-property')}</Text>
            <Text fontWeight="400" ml="2">
              KPID {state.selectedProperty.id}
            </Text>
          </HStack>
        </Box>
      ) : (
        translate('menu-title-home')
      )}
    </Box>
  );
}

function PropertiesNavigation({ isInActive }: { isInActive?: boolean }) {
  const { state, preSelectProperty } = useAppContext();
  const [isClick, setIsClick] = React.useState(false);

  const onClick = (property: PropertyType) => {
    if (state.map) {
      // console.log(state.map.getLayer())
      state.map.getMap().fire('click', {
        propertyTargetId: property.id,
        originalEvent: {},
      });

      setIsClick(true);
      preSelectProperty(property);
    }
  };

  React.useEffect(() => {
    if (state.preSelectedProperty && !isClick) {
      refs[state.preSelectedProperty.id].current?.scrollIntoView({
        block: 'center',
        inline: 'start',
      });
    }

    setIsClick(false);
  }, [state.preSelectedProperty]);

  const sortByName = (a: PropertyType, b: PropertyType) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  };

  const list = state.properties.sort(sortByName);

  const refs: any = list.reduce((acc: any, value) => {
    acc[value.id] = React.createRef();
    return acc;
  }, {});

  const bg = useColorModeValue('white', 'customGray.800');
  const bgSelected = useColorModeValue('customGray.20', 'customGray.700');
  const dropdownColor = useColorModeValue('black', 'white');
  const fontText = useColorModeValue('customGray.700', 'white');
  const pinColor = useColorModeValue('black', 'customGray.400');

  return (
    <Box bg={bg}>
      <List>
        {list.map((property) => {
          return (
            <ListItem
              ref={refs[property.id]}
              key={property.id}
              w="100%"
              bg={
                state.preSelectedProperty?.id === property.id ? bgSelected : ''
              }
            >
              <HStack
                borderBottom="1px"
                borderColor="customGray.40"
                h="16"
                p="0"
              >
                <Button
                  bg="inherit"
                  variant="link"
                  color={fontText}
                  _hover={{ textDecoration: 'none' }}
                  p="1rem 1.5rem"
                  textAlign="left"
                  w="100%"
                  h="100%"
                  onClick={() => {
                    onClick(property);
                  }}
                >
                  <Box minW="12%">
                    <Icon
                      as={PinIcon}
                      h="7"
                      w="7"
                      color={
                        state.preSelectedProperty?.id === property.id
                          ? 'green.500'
                          : pinColor
                      }
                    />
                  </Box>
                  <Text
                    noOfLines={[2]}
                    minW="73%"
                    fontWeight={
                      state.preSelectedProperty?.id === property.id
                        ? 'bold'
                        : 'inherit'
                    }
                    display="block"
                  >
                    {property.name}
                  </Text>
                  <Text minW="15%" fontSize="13" ml="auto">
                    KPID {property.id}
                  </Text>
                </Button>
              </HStack>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
}

function PropertyHeader() {
  const { state } = useAppContext();
  if (!state.selectedProperty) {
    return null;
  }

  return (
    <Box p="6" bg="green.500" color="white">
      <Text as="b" fontSize="20">
        {state.selectedProperty.name}
      </Text>
      <Text mt="0">
        {state.selectedProperty.region},{state.selectedProperty.city}
      </Text>
    </Box>
  );
}

function SpacesNavigation() {
  const { state } = useAppContext();
  const { translate } = useTranslation();
  if (!state.selectedProperty) {
    return null;
  }

  return (
    <Box>
      <Stack>
        <Button
          bg="customGray.700"
          h="72px"
          color="white"
          borderRadius="0px"
          justifyContent="left"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/';
          }}
        >
          <Icon h="6" w="6" mr="5" as={ReturnIcon} />
          {translate('back-to-properties')}
        </Button>
      </Stack>

      <PropertyHeader />
      <SpacesFilters />
    </Box>
  );
}

function BuildingsNavigation() {
  const { state } = useAppContext();
  const { translate } = useTranslation();

  if (!state.selectedProperty) {
    return null;
  }

  const sortByAddress = (a: BuildingType, b: BuildingType) => {
    if (a.address < b.address) return -1;
    if (a.address > b.address) return 1;
    return 0;
  };

  return (
    <Box>
      <Box>
        <Link to="/">&laquo; {translate('back-to-properties')}</Link>
      </Box>
      <ListItem>
        {state.selectedProperty?.buildings
          ?.sort(sortByAddress)
          .map((building) => (
            <List key={building.id}>
              <NavLink to={`/${state.selectedProperty?.id}/${building.id}`}>
                {building.address}
              </NavLink>
            </List>
          ))}
      </ListItem>
    </Box>
  );
}

function Navigation() {
  const { state, dispatch } = useAppContext();

  if (state.selectedProperty) {
    return (
      <Box mt="-96px" position="relative">
        <SpacesNavigation />
      </Box>
    );
  }

  return (
    <Box maxH="calc(var(--vh, 1vh)*100 - 11em)" overflowY="auto">
      <PropertiesNavigation />
    </Box>
  );
}

function MenuToggleButton({ isOpen, onClick, showNotice }: any) {
  const { translate } = useTranslation();
  const iconColor = useColorModeValue('customGray.900', 'white');
  const bg = useColorModeValue('#F6F7F7', 'customGray.800');
  return (
    <Button
      w="4.5rem"
      h="16"
      p="1.4rem"
      borderWidth="0px"
      borderRight="1px"
      borderBottom="1px"
      borderColor="customGray.30"
      borderRadius="none"
      bg={bg}
      onClick={onClick}
      _hover={{ bg: bg }}
    >
      {isOpen ? (
        <Icon h="6" w="6" color={iconColor} as={MenuCloseIcon} />
      ) : (
        <>
          {/* <MenuCloseIcon /> */}
          <Icon as={Hamburger} color={iconColor} h="6" w="6" />
        </>
      )}
    </Button>
  );
}

function Menu() {
  const { state, dispatch } = useAppContext();

  const setMenuOpen = (open: boolean) => {
    dispatch({
      type: 'SET_MAIN_MENU_OPEN',
      payload: open,
    });
  };
  const bg = useColorModeValue('white', 'customGray.800');
  useEffect(() => {
    setMenuOpen(window.innerWidth > 900);
  }, []);

  return (
    <>
      <Box position="absolute" zIndex="1001" className="menu-toggle">
        <MenuToggleButton
          showNotice={
            state.selectedProperty &&
            !state.mainMenuOpen &&
            !state.selectedSpace
          }
          isOpen={state.mainMenuOpen}
          onClick={() => setMenuOpen(!state.mainMenuOpen)}
        />
      </Box>
      <Box
        zIndex="1000"
        overflow="hidden"
        position="absolute"
        left="0"
        top="0"
        h="calc(var(--vh, 1vh) * 100)"
        maxW="25rem"
        w="100%"
        transition="all 0.2s ease"
        bg={bg}
        transform={state.mainMenuOpen ? 'translate(0)' : 'translate(-100%)'}
        id="menu-panel"
        boxShadow="xl"
      >
        <Box>
          <MenuHeader />
          <Search />
          <Navigation />
        </Box>
      </Box>
    </>
  );
}

export default Menu;
