import React, { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import {
  useColorModeValue,
  Icon,
  HStack,
  chakra,
  Text,
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
} from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../../../context/App';
import { useTranslation } from '../../../../context/Translations';
import { getPolygonBounds } from '../../../../utils';
import { SearchIcon, CircleIcon } from '../../../Icon';

export default function ParkingSpacesSearch() {
  const { state } = useAppContext();
  const { translate } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const clearSearch = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const doSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      inputRef.current &&
      inputRef.current !== null &&
      inputRef.current.value
    ) {
      console.log(inputRef.current?.value);
      const found = state.parkingSpaces.find(
        (parkingSpace) => parkingSpace.title === inputRef.current?.value,
      );

      if (found) {
        const params = new URLSearchParams(window.location.search);
        params.set('selectedParkingSpace', found.id);
        setSearchParams(params);
        clearSearch();
        state.map?.fitBounds(getPolygonBounds(found.geo), { maxZoom: 20 });
      } else {
        toast.error(translate('parking-space-not-found'));
      }
    }
  };

  const inputRef = useRef<HTMLInputElement>(null);
  const bg = useColorModeValue('white', 'customGray.800');
  const searchButtonStyle = {
    position: 'absolute',
    padding: '0.8em',
    h: '100%',
    w: '3.5rem',
    right: '0rem',
    borderTopLeftRadius: '0px',
    borderBottomLeftRadius: '2px',
    bg: 'green.500',
  };

  return (
    // <div className="search__input-wrap">
    //   <form onSubmit={doSearch}>
    //     <input
    //       ref={inputRef}
    //       id="search-input"
    //       type="text"
    //       className="search__input"
    //       name="q"
    //       placeholder={translate('parking-spaces-search-placeholder')}
    //     />
    //     <button
    //       type="submit"
    //       className="search__icon-btn search__icon-btn--clear"
    //     >
    //       <SearchIcon />
    //     </button>
    //   </form>
    // </div>
    <Box py="0rem" px="1.5rem" mb="1.5rem">
      <chakra.form onSubmit={doSearch}>
        <InputGroup h="56px" w="100%">
          <Input
            bg={bg}
            ref={inputRef}
            id="search-input"
            placeholder={translate('parking-spaces-search-placeholder')}
            border="1px"
            borderColor="customGray.600"
            p=".75rem"
            pr="2.25rem"
            h="100%"
            _focusVisible={{ outline: 'none', boxShadow: '0 0 0 2px #008151' }}
          />
          <InputRightElement margin="0px" h="100%" width="4.5rem">
            <Button type="submit" sx={searchButtonStyle}>
              <Icon color="white" h="1.8rem" w="1.8rem" as={SearchIcon} />
            </Button>
          </InputRightElement>
        </InputGroup>
        <Box mt="1.5rem">
          <VStack alignItems="baseline">
            <HStack>
              <Icon color="green.500" h="1.5rem" w="1.5rem" as={CircleIcon} />
              <Text>{translate('Vapaa')}</Text>
            </HStack>
            <HStack>
              <Icon color="green.100" h="1.5rem" w="1.5rem" as={CircleIcon} />
              <Text>Pian vapaana</Text>
            </HStack>
            <HStack>
              <Icon
                color="customGray.200"
                h="1.5rem"
                w="1.5rem"
                as={CircleIcon}
              />
              <Text>{translate('Varattu')}</Text>
            </HStack>
          </VStack>
        </Box>
      </chakra.form>
    </Box>
  );
}
