import React from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import {
  useColorModeValue,
  Icon,
  HStack,
  Switch,
  Text,
  Box,
  ListItem,
} from '@chakra-ui/react';
import { useTranslation } from '../../../../context/Translations';
import { ToggleButton } from '../../../ToggleButton';
import ParkingSpacesSearch from './ParkingSpacesSearch';

import { ParkingIcon } from '../../../Icon';

export function ParkingSpacesMenu() {
  const navigate = useNavigate();
  const location = useLocation();
  const { translate } = useTranslation();
  const [searchParams] = useSearchParams();

  const isActive = searchParams.get('showParking') !== null;
  const subFontColor = useColorModeValue('customGray.700', 'white');
  const propertyLocation = location.pathname.split('/')[1].toString();
  const pathname = `/${propertyLocation}`;
  return (
    <ListItem
      w="100%"
      display="flex"
      borderBottom="1px"
      borderColor="customGray.40"
      flexFlow="column"
      mb="40"
      color={subFontColor}
    >
      <HStack p="1.5rem" h="4rem">
        <Icon as={ParkingIcon} mr="1.5" h="7" w="7" />
        <Text w="85%">{translate('parking-spaces')}</Text>
        <Box w="15%">
          <ToggleButton
            id="parking-spaces-menu"
            checked={isActive}
            onChange={(checked) => {
              navigate({
                pathname: pathname,
                search: checked ? `?showParking` : undefined,
              });
            }}
          />
        </Box>
      </HStack>
      {isActive && <ParkingSpacesSearch />}
    </ListItem>
  );
}
