import React, { useEffect, useRef, useState } from 'react';
import { useMap } from 'react-map-gl';
import {
  useColorMode,
  useColorModeValue,
  List,
  Icon,
  Box,
  IconButton,
} from '@chakra-ui/react';

import { CloseIcon, LayersIcon } from '../Icon';
import RadioButton from '../RadioButton';

type MapStyleItem = {
  url: string;
  title: string;
};

const MAPSTYLES: MapStyleItem[] = [
  {
    url: 'mapbox://styles/mapbox/dark-v9',
    title: 'tumma',
  },
  {
    url: 'mapbox://styles/mapbox/light-v9',
    title: 'vaalea',
  },
  {
    url: 'mapbox://styles/mapbox/satellite-v9',
    title: 'satelliitti',
  },
];

function MapStyleItem({
  mapstyle,
  onChange,
  current,
}: {
  mapstyle: MapStyleItem;
  onChange: (e: any) => void;
  current: string;
}) {
  return (
    <List mr="15px" className="mapstyle-select__item">
      <RadioButton
        onChange={onChange}
        id={mapstyle.url}
        value={mapstyle.url}
        name="layerStyle"
        label={mapstyle.title}
        checked={
          current === mapstyle.url ||
          (!current && mapstyle.url === MAPSTYLES[0].url)
        }
      />
    </List>
  );
}

function MapStyleSelect() {
  const [showList, setShowList] = useState<boolean>(false);
  const [currentStyle, setCurrentStyle] = useState<string>(
    localStorage.getItem('layerStyle') || '',
  );
  const { mainMap } = useMap();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { setColorMode } = useColorMode();

  const onChange = (e: any) => {
    const { value } = e.target;
    localStorage.setItem('layerStyle', value);
    setCurrentStyle(value);
    mainMap?.getMap().setStyle(value);
    if (value === 'mapbox://styles/mapbox/dark-v9') {
      setColorMode('dark');
    } else {
      setColorMode('light');
    }
  };

  useEffect(() => {
    function handleClickOutside(event: any): void {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        showList
      ) {
        setShowList(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, showList]);

  const bg = useColorModeValue('white', 'customGray.800');
  return (
    <Box
      position="fixed"
      bottom="44"
      right="1rem"
      display="flex"
      alignItems="baseline"
      ref={wrapperRef}
      className="mapstyle-select"
    >
      <List
        m="0"
        p="4"
        pr="12"
        display={showList ? 'flex' : 'none'}
        transform="translate(3.25rem, 0.25rem)"
        borderRadius="5"
        shadow="xl"
        bg={bg}
      >
        {MAPSTYLES.map((mapstyle) => (
          <MapStyleItem
            key={mapstyle.url}
            onChange={onChange}
            mapstyle={mapstyle}
            current={currentStyle}
          />
        ))}
      </List>

      <Box>
        <IconButton
          aria-label="Select map style"
          bg="white"
          h="12"
          w="12"
          shadow={showList ? 'none' : 'xl'}
          border="none"
          mb="8px"
          _hover={{ bg: 'white' }}
          _focus={{ bg: 'white' }}
          onClick={() => setShowList((current) => !current)}
          type="button"
          className={`button mapstyle-select__toggle-btn ${
            showList ? 'mapstyle-select__toggle-btn--close' : ''
          }`}
        >
          {showList ? (
            <Icon w="4" h="4" color="black" as={CloseIcon} />
          ) : (
            <Icon w="6" h="6" as={LayersIcon} />
          )}
        </IconButton>
      </Box>
    </Box>
  );
}

export default MapStyleSelect;
