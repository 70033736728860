import React, { useMemo } from 'react';
import { SymbolLayer, Layer, Source } from 'react-map-gl';
import { useAppContext } from '../../../context/App';
import { Entrance } from '../../../types';
import { isOnSameFloor } from '../../../utils';

const LAYER_ID = 'entrances';
const SOURCE_ID = 'entrances-data';

const layerStyleEntrances: SymbolLayer = {
  id: LAYER_ID,
  type: 'symbol',
  minzoom: 17.8,
  layout: {
    'icon-image': ['get', 'icon'],
    // zoom based icon size
    'icon-size': [
      'interpolate',
      ['linear'],
      ['zoom'],
      15,
      0,
      17,
      0.5,
      17.5,
      0.6,
      18,
      0.7,
      18.5,
      1,
    ],
    'icon-allow-overlap': true,
    'icon-ignore-placement': true,
    'text-field': ['get', 'name'],
    'icon-anchor': 'center',
    // 'text-allow-overlap': true,
    // 'text-ignore-placement': true,
    'text-size': [
      'interpolate',
      ['linear'],
      ['zoom'],
      15,
      0,
      17,
      8,
      17.5,
      10,
      18,
      12,
      18.5,
      16,
    ],
    'text-line-height': 1,
    'text-offset': ['get', 'textOffset'],
    'text-anchor': 'center',
    // 'text-justify': 'right',
    'text-font': ['Open Sans Bold'],
  },
  paint: {
    'text-color': 'white',
  },
};

const getMetadataProps = (
  metadata: Entrance['metadata'],
  postfix = '-inactive',
) => {
  const metadataProps: { icon: string; textOffset: number[] } = {
    icon: `entrance${postfix}`,
    textOffset: [0.5, 0],
  };

  if (metadata.accessibility && metadata.iloq) {
    return {
      icon: `entrance-iloq-accessible${postfix}`,
      textOffset: [-0.6, 0],
    };
  }

  if (metadata.accessibility) {
    return {
      icon: `entrance-accessible${postfix}`,
      textOffset: [0, 0],
    };
  }

  // if iloq
  if (metadata.iloq) {
    return {
      icon: `entrance-iloq${postfix}`,
      textOffset: [0, 0],
    };
  }

  return metadataProps;
};

function Entrances() {
  const { state } = useAppContext();
  const geojsonEntrances: any = useMemo(
    () => ({
      type: 'FeatureCollection',
      features: state.entrances.map((entrance: Entrance) => {
        const metadataProps = getMetadataProps(
          entrance.metadata,
          state.selectedSpace?.floors &&
            isOnSameFloor(state.selectedSpace?.floors, entrance.floors)
            ? '-active'
            : '-inactive',
        );
        return {
          type: 'Feature',
          id: +entrance.fid,
          geometry: entrance.geo,
          properties: {
            ...entrance,
            metadata: {
              accessibility: true,
              iloq: true,
            },
            categoryId: entrance.category.id,
            categoryName: entrance.category.name,
            ...metadataProps,
          },
        };
      }),
    }),
    [state.entrances, state.selectedSpace],
  );

  return (
    <Source id={SOURCE_ID} type="geojson" data={geojsonEntrances}>
      <Layer {...layerStyleEntrances} />
    </Source>
  );
}

export default Entrances;
