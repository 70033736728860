import React, { createContext, useReducer, Dispatch, useEffect } from 'react';
import { doApiRequest } from '../lib/api';

import {
  AppStateType,
  AreaType,
  BuildingType,
  ParkingSpace,
  PropertyType,
  SpaceType,
} from '../types';
import { useAuth } from './Auth';

type ContextProps = {
  state: AppStateType;
  preSelectProperty: Dispatch<any>;
  selectProperty: Dispatch<any>;
  selectBuilding: Dispatch<any>;
  selectSpace: Dispatch<any>;
  selectArea: Dispatch<any>;
  selectParkingSpace: Dispatch<any>;
  fetchProperties: (token: any) => any;
  fetchProperty: (id: number) => any;
  fetchParkingSpaces: (id: number) => any;
  fetchEntrances: (id: number) => any;
  dispatch: React.Dispatch<any>;
};

const initialState: AppStateType = {
  properties: [],
  buildings: [],
  spaces: [],
  areas: [],
  parkingSpaces: [],
  entrances: [],
  selectedProperty: null,
  preSelectedProperty: null,
  selectedBuilding: null,
  selectedSpace: null,
  selectedArea: null,
  selectedParkingSpace: null,
  appLoading: true,
  mainMenuOpen: true,
  map: null,
};

const mainReducer = (state: AppStateType, action: any): AppStateType => {
  switch (action.type) {
    case 'ADD_PROPERTIES':
      return {
        ...state,
        properties: [...action.payload],
      };
    case 'ADD_PROPERTY':
      return {
        ...state,
        properties: [action.payload, ...state.properties],
      };
    case 'SELECT_PROPERTY':
      return {
        ...state,
        selectedProperty: action.payload || null,
      };
    case 'PRE_SELECT_PROPERTY':
      return {
        ...state,
        preSelectedProperty: action.payload || null,
      };
    case 'SELECT_BUILDING':
      return {
        ...state,
        selectedBuilding: action.payload || null,
      };
    case 'SELECT_SPACE':
      return {
        ...state,
        selectedSpace: action.payload || null,
      };
    case 'SELECT_AREA':
      return {
        ...state,
        selectedArea: action.payload || null,
      };
    case 'SELECT_PARKING_SPACE':
      return {
        ...state,
        selectedParkingSpace: action.payload || null,
      };
    case 'ADD_PARKING_SPACES':
      return {
        ...state,
        parkingSpaces: action.payload || [],
      };
    case 'ADD_ENTRANCES':
      return {
        ...state,
        entrances: action.payload || [],
      };
    case 'SET_APP_LOADING':
      return {
        ...state,
        appLoading: !!action.payload,
      };
    case 'SET_MAIN_MENU_OPEN':
      return {
        ...state,
        mainMenuOpen: !!action.payload,
      };
    case 'SET_MAP':
      return {
        ...state,
        map: action.payload,
      };
    default:
      return state;
  }
};

const AppContext = createContext<ContextProps>({
  state: initialState,
  preSelectProperty: (property: PropertyType) => property,
  selectProperty: (property: PropertyType) => property,
  selectBuilding: (building: BuildingType) => building,
  selectSpace: (space: SpaceType) => space,
  selectArea: (area: AreaType) => area,
  selectParkingSpace: (parkingSpace: ParkingSpace) => parkingSpace,
  fetchProperties: (token: string) => null,
  fetchProperty: (id: number) => null,
  fetchParkingSpaces: (id: number) => null,
  fetchEntrances: (id: number) => null,
  dispatch: () => null,
});

export function AppProvider({ children }: any) {
  const auth = useAuth();

  const [state, dispatch] = useReducer(mainReducer, initialState);

  // Actions for changing state
  const contextValue = React.useMemo(() => {
    const selectProperty = (property: PropertyType) => {
      dispatch({
        type: 'SELECT_PROPERTY',
        payload: property,
      });
    };

    const preSelectProperty = (property: PropertyType) => {
      dispatch({
        type: 'PRE_SELECT_PROPERTY',
        payload: property,
      });
    };

    const selectBuilding = (building: BuildingType) => {
      dispatch({
        type: 'SELECT_BUILDING',
        payload: building,
      });
    };

    const selectSpace = (space: SpaceType) => {
      dispatch({
        type: 'SELECT_SPACE',
        payload: space,
      });
    };

    const selectArea = (area: AreaType | null) => {
      dispatch({
        type: 'SELECT_AREA',
        payload: area,
      });
    };

    const selectParkingSpace = (parkingSpace: ParkingSpace | null) => {
      dispatch({
        type: 'SELECT_PARKING_SPACE',
        payload: parkingSpace,
      });
    };

    const fetchProperties = async (token: string) => {
      dispatch({
        type: 'SET_APP_LOADING',
        payload: true,
      });

      try {
        const data = await doApiRequest('/properties', 'GET', token);
        if (data) {
          dispatch({ type: 'ADD_PROPERTIES', payload: data });
        }
      } catch (e) {
        console.error(e);
      } finally {
        dispatch({
          type: 'SET_APP_LOADING',
          payload: false,
        });
      }
    };

    const fetchProperty = async (id: number) => {
      try {
        const data = await doApiRequest(
          `/properties/${id}`,
          'GET',
          auth.authState.userToken,
        );
        if (data) {
          selectProperty(data);
          await fetchEntrances(id);
        }
      } catch (e) {
        console.error(e);
      }
    };

    const fetchParkingSpaces = async (id: number) => {
      const data = await doApiRequest(
        `/properties/${id}/parking`,
        'GET',
        auth.authState.userToken,
      );

      dispatch({ type: 'ADD_PARKING_SPACES', payload: data });
    };

    const fetchEntrances = async (id: number) => {
      const data = await doApiRequest(
        `/properties/${id}/entrances`,
        'GET',
        auth.authState.userToken,
      );

      dispatch({ type: 'ADD_ENTRANCES', payload: data });
    };

    return {
      state,
      fetchProperties,
      fetchProperty,
      fetchParkingSpaces,
      fetchEntrances,
      selectProperty,
      preSelectProperty,
      selectBuilding,
      selectArea,
      selectSpace,
      selectParkingSpace,
      dispatch,
    };
  }, [state]);

  useEffect(() => {
    if (auth.authState.userToken && auth.authState.isLoggedIn) {
      contextValue.fetchProperties(auth.authState.userToken);
    }
  }, [auth.authState.userToken, auth.authState.isLoggedIn]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

export default AppProvider;

export function useAppContext() {
  return React.useContext(AppContext);
}
