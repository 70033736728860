import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import toast from 'react-hot-toast';

import TextInput from '../../components/TextInput';

import { useAuth } from '../../context/Auth';
import { useTranslation } from '../../context/Translations';

import './Login.scss';

function LoginPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useAuth();
  const { translate } = useTranslation();
  const state = location.state as { from: any; isLogout?: boolean };
  const from: string = state?.from || '/';

  useEffect(() => {
    if (state?.isLogout) {
      toast.success(translate('log-out-success'));
      navigate('/', { replace: true });
    } else if (auth.authState.userToken) {
      navigate('/', { replace: true });
    }
  }, []);

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData(event.currentTarget);
    const username = formData.get('username') as string;
    const password = formData.get('password') as string;
    auth
      .signIn({ username, password })
      .then((data: { success: boolean; message: string }) => {
        if (data.success) {
          toast.success(translate(data.message));
          navigate(from, { replace: true });
        } else {
          toast.error(translate(data.message));
        }
      })
      .finally(() => setIsLoading(false));
  }

  if (auth.authState.userToken) {
    return null;
  }

  return (
    <div className="login-page">
      <div className="col col--center">
        <h1 className="text-center">{translate('login-page-heading')}</h1>
        <h3 className="margin-bottom--m text-center">
          {translate('login-page-subheading')}
        </h3>
        <p className="margin-bottom--md text-center">
          {translate('login-page-text')}
        </p>
        <form className="form" onSubmit={handleSubmit}>
          <div className="margin-bottom--md">
            <TextInput
              id="username"
              name="username"
              type="email"
              placeholder={translate('username-placeholder')}
              label={translate('username')}
              autoComplete="email"
              required
            />
          </div>
          <div className="margin-bottom--md">
            <TextInput
              id="password"
              name="password"
              type="password"
              placeholder={translate('password-placeholder')}
              label={translate('password')}
              autoComplete="current-password"
              required
            />
          </div>

          <div className="text-center">
            <button
              disabled={isLoading}
              className={`button button--primary button--green button--large button--wide ${
                isLoading ? 'button--loading' : ''
              }`}
              type="submit"
            >
              {isLoading ? translate('login-loading') : translate('login')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
