import {
  Box,
  HStack,
  Icon,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { point } from '@turf/helpers';
import polylabel from 'polylabel';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import {
  FillLayer,
  Layer,
  LineLayer,
  Popup,
  Source,
  SymbolLayer,
  useMap,
} from 'react-map-gl';
import { useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../../context/App';
import { useTranslation } from '../../../context/Translations';
import { BuildingType, SpaceType } from '../../../types';
import { getCategoryColor, getFloorsString } from '../../../utils';
import {
  BusinessPremisesicon,
  ClubRoomIcon,
  CorridorIcon,
  LaudryIcon,
  MaintenanceIcon,
  ProtectionFacilityIcon,
  SaunaIcon,
  StairsIcon,
  StorageIcon,
  TrashIcon,
} from '../../Icon';

type HoverInfoProps = {
  features: Array<any>;
  x: number;
  y: number;
};

function findCenterOfPolygon(feature: any) {
  let output = [];
  if (feature.geometry.type === 'Polygon') {
    output = polylabel(feature.geometry.coordinates, 0.000001);
  } else {
    const p = feature.geometry.coordinates[0];
    output = polylabel(p, 0.000001);
  }
  return output;
}

function findNorthestPoint(geojson: any): number[] {
  let coordinates: number[][][] = [];
  if (geojson.type === 'Polygon') {
    coordinates = [geojson.coordinates];
  } else if (geojson.type === 'MultiPolygon') {
    coordinates = geojson.coordinates;
  } else {
    throw new Error('Invalid GeoJSON type');
  }

  let northestPoint: number[] = [0, 0];
  coordinates.forEach((polygon) => {
    polygon.forEach((ring) => {
      ring.forEach((p: any) => {
        if (p[1] > northestPoint[1]) {
          northestPoint = p;
        }
      });
    });
  });

  return northestPoint;
}

const LAYER_ID = 'spaces';
const LAYER_ID_SYMBOLS = 'spaces-symbols';
const LAYER_ID_HOVER = 'spaces-hover';
const SOURCE_ID = 'spaces-data';
const SOURCE_ID_SYMBOLS = 'spaces-data-symbols';
const COLOR_SELECTED = '#007663';

const ELEVATOR_CATEGORIES = ['TRHK', 'TRHI'];
const STAIRWELL_CATEGORIES = ['TRKP'];
const All_CATEGORIES = [
  'TRPE',
  'TRSA',
  'TRVS',
  'TRVT',
  'TRLT',
  'TRKH',
  'TRJL',
  'TRHT',
];

const layerStyleSpacesSymbols: SymbolLayer = {
  id: LAYER_ID_SYMBOLS,
  type: 'symbol',
  source: 'places',
  layout: {
    'icon-image': ['get', 'categoryId'],
  },
};

const layerStyleSpaces: FillLayer = {
  id: LAYER_ID,
  type: 'fill',
  paint: {
    'fill-color': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      COLOR_SELECTED,
      // '#79D9CA',
      ['get', 'color'],
    ],
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'selected'], false],
      1,
      0.75,
    ],
    // 'fill-extrusion-color': ['get', 'color'],
    // 'fill-extrusion-height': ['get', 'height'],
    // 'fill-extrusion-base': ['get', 'baseHeight'],
    // 'fill-extrusion-opacity': 0.5,
  },
};

const layerStyleSpacesStroke: LineLayer = {
  id: `${LAYER_ID}-stroke`,
  type: 'line',
  layout: {
    'line-sort-key': 0,
    'line-cap': 'round',
  },
  paint: {
    'line-color': '#000000',
    'line-width': 1.5,
    'line-opacity': 0.5,
    // 'line-dasharray': [3, 6],
  },
};

const layerHoverStyle: LineLayer = {
  id: LAYER_ID_HOVER,
  type: 'line',
  paint: {
    'line-width': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      2,
      0,
    ],
  },
};

function ToolTip({ tooltipInfo }: any) {
  return (
    <Box
      position={isMobile ? 'sticky' : 'absolute'}
      m="2"
      p="2"
      background="black"
      color="white"
      maxWidth="72"
      fontSize="14"
      zIndex="9"
      pointerEvents="none"
      left={tooltipInfo.x}
      top={tooltipInfo.y}
    >
      {tooltipInfo.features.map((feature: any) => (
        <Box key={feature.properties.name}>
          <Text as="b">
            {feature.properties.name} ({feature.properties.floorsString})
          </Text>
          <Text>{feature.properties.categoryName}</Text>
        </Box>
      ))}
    </Box>
  );
}

function SelectedSpacePopup() {
  const { state } = useAppContext();
  const { translate } = useTranslation();
  const bg = useColorModeValue('white', 'gray.900');

  if (!state.selectedSpace) {
    return null;
  }

  const geojson = state.selectedSpace.geo;

  // Get northest point of polygon
  const coordinates = findNorthestPoint(geojson);

  const feature = point(coordinates, {
    ...state.selectedSpace,
  });

  const floorsString = (floors: SpaceType['floors']) => {
    return `${translate(
      floors.length === 1 ? 'floor' : 'floors',
    )}: ${getFloorsString(floors)}`;
  };

  let legendIcon = StairsIcon;
  if (feature.properties?.id.indexOf('TRKH') > -1) {
    legendIcon = ClubRoomIcon;
  } else if (feature.properties?.id.indexOf('TRKP') > -1) {
    legendIcon = CorridorIcon;
  } else if (feature.properties?.id.indexOf('TRLT') > -1) {
    legendIcon = BusinessPremisesicon;
  } else if (feature.properties?.id.indexOf('TRPE') > -1) {
    legendIcon = LaudryIcon;
  } else if (feature.properties?.id.indexOf('TRSA') > -1) {
    legendIcon = SaunaIcon;
  } else if (feature.properties?.id.indexOf('TRVT') > -1) {
    legendIcon = StorageIcon;
  } else if (feature.properties?.id.indexOf('TRHT') > -1) {
    legendIcon = MaintenanceIcon;
  } else if (feature.properties?.id.indexOf('TRHI') > -1) {
    legendIcon = StairsIcon;
  } else if (feature.properties?.id.indexOf('TRJL') > -1) {
    legendIcon = TrashIcon;
  } else if (feature.properties?.id.indexOf('TRVS') > -1) {
    legendIcon = ProtectionFacilityIcon;
  }

  return (
    <Popup
      maxWidth="auto"
      anchor="bottom"
      className="selected-space-popup"
      closeOnClick={false}
      closeButton={false}
      latitude={feature.geometry.coordinates[1]}
      longitude={feature.geometry.coordinates[0]}
    >
      <HStack boxShadow="xl" p="4" bg={bg} opacity="0.9">
        <Icon h="14" w="14" mr="2" as={legendIcon} />
        <VStack alignItems="baseline">
          <Text as="b" fontSize="16">
            {feature.properties?.name}
          </Text>
          {feature.properties?.floors ? (
            <Text>{floorsString(feature.properties?.floors)}</Text>
          ) : null}
        </VStack>
      </HStack>
    </Popup>
  );
}

const getSpaces = (buildings: BuildingType[]) =>
  buildings.map((b) => b.spaces).flat();

let hoveredId: any = null;
let selectedId: any = null;
function Spaces() {
  const { mainMap } = useMap();
  const { state } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = searchParams.getAll('filters[]');
  const { translate } = useTranslation();
  const [hoverInfo, setHoverInfo] = React.useState<HoverInfoProps | null>(null);

  const floorsString = (floors: SpaceType['floors']) => {
    return `${translate(
      floors.length === 1 ? 'floor' : 'floors',
    )}: ${getFloorsString(floors)}`;
  };

  const spaces = React.useMemo(
    () => getSpaces(state.selectedProperty?.buildings || []),
    [state.selectedProperty],
  );

  const geojsonSpaces: any = React.useMemo(
    () => ({
      type: 'FeatureCollection',
      features: spaces.map((space: SpaceType) => ({
        type: 'Feature',
        id: +space.fid,
        geometry: space.geo,
        properties: {
          selected: state.selectedSpace?.id === space.id,
          spaceId: space.id,
          floorsString: floorsString(space.floors),
          floorsArray: space.floors.map((f) => f.floor),
          // height: 1,
          // base_height: +space.floorName,
          color: getCategoryColor(space.category?.id),
          ...space,
          categoryId: space.category?.id,
          categoryName: space.category?.name,
        },
      })),
    }),
    [state.selectedProperty?.id],
  );

  const geojsonSymbols: any = React.useMemo(
    () => ({
      type: 'FeatureCollection',
      features: geojsonSpaces.features
        .filter((f: any) => f.id !== state.selectedSpace?.id)
        .map((f: any) => {
          const center = findCenterOfPolygon(f);
          return point(center, {
            ...f.properties,
          });
        }),
    }),
    [geojsonSpaces],
  );

  const setHoverFeatureState = (id: number | null) => {
    // un-hilight current
    if (hoveredId !== null) {
      mainMap?.setFeatureState(
        { source: SOURCE_ID, id: hoveredId },
        { hover: false },
      );

      hoveredId = null;
    }

    // if feature id provided. set feature state
    if (id) {
      hoveredId = id;
      mainMap?.setFeatureState(
        { source: SOURCE_ID, id: hoveredId },
        { hover: true },
      );
    }
  };

  const setSelectedFeatureState = (id: number | null) => {
    // un-hilight current
    if (selectedId !== null) {
      mainMap?.setFeatureState(
        { source: SOURCE_ID, id: selectedId },
        { selected: false },
      );

      selectedId = null;
    }

    // if feature id provided. set feature state
    if (id) {
      selectedId = id;
      mainMap?.setFeatureState(
        { source: SOURCE_ID, id: selectedId },
        { selected: true },
      );
    }
  };

  const onHover = React.useCallback((event) => {
    const {
      features,
      point: { x, y },
    } = event;

    const hoveredFeature = features && features[0];
    setHoverFeatureState(hoveredFeature.id);
    setHoverInfo(hoveredFeature ? { features, x, y } : null);
  }, []);

  const onMouseLeave = () => {
    setHoverFeatureState(null);
    setHoverInfo(null);
  };

  useEffect(() => {
    setSelectedFeatureState(state.selectedSpace?.fid || null);
  }, [state.selectedSpace]);

  useEffect(() => {
    mainMap?.on('mousemove', LAYER_ID, onHover);
    mainMap?.on('mouseleave', LAYER_ID, onMouseLeave);
  }, []);

  const customFilters = React.useMemo(() => {
    const categories = [
      ...ELEVATOR_CATEGORIES,
      ...STAIRWELL_CATEGORIES,
      ...All_CATEGORIES,
    ];
    if (
      !state.selectedSpace ||
      !state.selectedSpace?.category ||
      !categories.includes(state.selectedSpace?.category.id)
    ) {
      return [];
    }

    if (!state.selectedSpace?.floors) {
      return [];
    }
    // Find Nearby spaces
    const floor = state.selectedSpace?.floors[0].floor;

    return (
      state.selectedBuilding?.spaces
        .filter(
          (space) =>
            space.category &&
            space.floors.map((f) => f.floor).includes(floor) &&
            categories.includes(space.category.id),
        )
        ?.map((s) => s.id) || []
    );
  }, [state.selectedSpace?.id]);

  const filter = React.useMemo(
    () => [
      'in',
      'spaceId',
      ...[state.selectedSpace?.id || ''].concat(customFilters),
    ],
    [state.selectedSpace?.id],
  );

  const filterSymbols = React.useMemo(
    () => ['in', 'spaceId', ...customFilters],
    [state.selectedSpace?.id],
  );

  return (
    <>
      <Source id={SOURCE_ID} type="geojson" data={geojsonSpaces}>
        <Layer {...layerStyleSpaces} filter={filter} />
        <Layer {...layerHoverStyle} filter={filter} />
        <Layer {...layerStyleSpacesStroke} filter={filter} />
      </Source>
      <Source id={SOURCE_ID_SYMBOLS} type="geojson" data={geojsonSymbols}>
        <Layer {...layerStyleSpacesSymbols} filter={filterSymbols} />
      </Source>
      {hoverInfo && <ToolTip tooltipInfo={hoverInfo} />}
      <SelectedSpacePopup />
    </>
  );
}

export default Spaces;
