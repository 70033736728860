import { MapRef } from 'react-map-gl';
import {
  businessPremiseMarker,
  clubRoomMarker,
  hallWayMarker,
  laundryMarker,
  maintenanceMarker,
  populationProtectionMarker,
  saunaMarker,
  stairsMarker,
  storageMarker,
  EntranceAccessibilityBgIcon,
  EntranceAccessibilityIloqBgIcon,
  EntranceBgIcon,
  EntranceIloqBgIcon,
  EntranceAccessibilityBgInactiveIcon,
  EntranceAccessibilityIloqBgInactiveIcon,
  EntranceBgInactiveIcon,
  EntranceIloqBgInactiveIcon,
} from '../../Icon';

const icons = {
  TRKP: {
    src: hallWayMarker,
    width: 40,
    height: 45,
  },
  TRHK: {
    src: stairsMarker,
    width: 40,
    height: 45,
  },
  TRHI: {
    src: stairsMarker,
    width: 40,
    height: 45,
  },
  TRSA: {
    src: saunaMarker,
    width: 40,
    height: 45,
  },
  TRPE: {
    src: laundryMarker,
    width: 40,
    height: 45,
  },
  TRKH: {
    src: clubRoomMarker,
    width: 40,
    height: 45,
  },
  TRLT: {
    src: businessPremiseMarker,
    width: 40,
    height: 45,
  },
  TRVT: {
    src: storageMarker,
    width: 40,
    height: 45,
  },
  TRVS: {
    src: populationProtectionMarker,
    width: 40,
    height: 45,
  },
  TRHT: {
    src: maintenanceMarker,
    width: 40,
    height: 45,
  },
  'entrance-active': {
    src: EntranceBgIcon.default,
    width: 45,
    height: 28,
  },
  'entrance-accessible-active': {
    src: EntranceAccessibilityBgIcon.default,
    width: 64,
    height: 28,
  },
  'entrance-iloq-active': {
    src: EntranceIloqBgIcon.default,
    width: 64,
    height: 28,
  },
  'entrance-iloq-accessible-active': {
    src: EntranceAccessibilityIloqBgIcon.default,
    width: 83,
    height: 28,
  },
  'entrance-inactive': {
    src: EntranceBgInactiveIcon.default,
    width: 45,
    height: 28,
  },
  'entrance-accessible-inactive': {
    src: EntranceAccessibilityBgInactiveIcon.default,
    width: 64,
    height: 28,
  },
  'entrance-iloq-inactive': {
    src: EntranceIloqBgInactiveIcon.default,
    width: 64,
    height: 28,
  },
  'entrance-iloq-accessible-inactive': {
    src: EntranceAccessibilityIloqBgInactiveIcon.default,
    width: 83,
    height: 28,
  },
};

export const loadImage = (id: string, map: MapRef): void => {
  if (map.hasImage(id) || !icons[id as keyof typeof icons]) {
    return;
  }

  const icon = icons[id as keyof typeof icons];
  const img = new Image(icon.width, icon.height);
  img.onload = () => {
    if (!map.hasImage(id)) {
      map.addImage(id, img);
    }
  };
  img.src = icon.src;
};
