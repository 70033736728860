export default {
  blue: {
    50: '#EEEEFF',
    100: '#EEEEFF',
    200: '#e5e8ff',
    300: '#b5bcff',
    400: '#858ff9',
    500: '#5865F6',
    600: '#37419B',
    700: '#37419B',
    800: '#0074bf',
  },
  green: {
    50: '#E6F5F0',
    100: '#64DCC3',
    200: '#34f3d3',
    300: '#22d9b9',
    400: '#14aa90',
    500: '#078571',
    600: '#056455',
    700: '#00493e',
    800: '#001a15',
    900: '#001a15',
  },
  purple: {
    1: '#828cfa',
  },
  customGray: {
    10: '#F6F7F7',
    20: '#F0F1F1',
    30: '#E0E0E0',
    40: '#c7c7c7',
    50: '#EBEDEE',
    100: '#D7DBDC',
    200: '#C3C9CB',
    300: '#AFB7BA',
    400: '#879397',
    500: '#5F6F75',
    600: '#4B5D63',
    700: '#374B52',
    800: '#1C2629',
    900: '#0E1314',
  },
};
