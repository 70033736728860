import mapboxgl from 'mapbox-gl';
import {
  BusinessPremisesicon,
  ClubRoomIcon,
  CorridorIcon,
  LaudryIcon,
  MaintenanceIcon,
  ProtectionFacilityIcon,
  SaunaIcon,
  StairsIcon,
  StorageIcon,
  TrashIcon,
} from '../components/Icon';
import { FloorType, SpaceType } from '../types';

export const getPolygonBounds = (geometry: any) => {
  let bounds = null;
  const coordinates: any = [];
  if (geometry.type === 'MultiPolygon') {
    geometry.coordinates.forEach((coords: any) => {
      coords.map((g: any) => coordinates.push(...g));
    });
    bounds = coordinates.reduce(
      (b: any, coord: any) =>
        // eslint-disable-next-line no-undef
        b.extend(coord),
      new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]),
    );
  } else {
    geometry.coordinates.map((g: any) => coordinates.push(...g));
    bounds = coordinates.reduce(
      (b: any, coord: any) =>
        // eslint-disable-next-line no-undef
        b.extend(coord),
      new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]),
    );
  }
  return bounds;
};

export const getFloorsString = (floors: SpaceType['floors']) => {
  if (floors.length === 1) {
    return floors[0].name;
  }

  return `${floors[0].name} - ${floors[floors.length - 1].name}`;
};

export const getCategoryColor = (categoryId: string | undefined) => {
  const colors: any = {
    PP: '#a1a7ef',
    TRHK: '#2F3DBB', // Hissi ja porras // Lift and hallway
    TRJT: '#4F33F7', // Jätteenlajittelu // Waste
    TRPE: '#9098f9', // Pesutilat //Laundry
    TRSA: '#007fcf', // Saunatilat //Sauna
    TRVS: '#40C5E2', // Väestönsuoja //Population protection
    TRVT: '#4264fb', // Varastotilat //Storage
    TRKP: '#0F197D', // Käytävä- ja porrastilat // Stairs and Hallway
    TRHI: '#2F3DBB', // Hissit // Lift
    TRHT: '#2F3DBB', // Huolto- ja tekniset tilat //Maintenance
  };
  // return colors[categoryId] || '#000000';
  let defaultColor = '#79D9CA';
  if (categoryId === 'TRKP') {
    defaultColor = '#ffffff';
  }
  return defaultColor;
};

export const getCategoryIconById = (categoryId: string) => {
  let icon = StairsIcon;
  switch (categoryId) {
    case 'TRKH':
      icon = ClubRoomIcon;
      break;
    case 'TRKP':
      icon = CorridorIcon;
      break;
    case 'TRLT':
      icon = BusinessPremisesicon;
      break;
    case 'TRPE':
      icon = LaudryIcon;
      break;
    case 'TRSA':
      icon = SaunaIcon;
      break;
    case 'TRVT':
      icon = StorageIcon;
      break;
    case 'TRHT':
      icon = MaintenanceIcon;
      break;
    case 'TRHI':
      icon = StairsIcon;
      break;
    case 'TRJL':
      icon = TrashIcon;
      break;
    case 'TRVS':
      icon = ProtectionFacilityIcon;
      break;
    default:
      break;
  }
  return icon;
};

export const isOnSameFloor = (
  needle: FloorType[],
  haystack: FloorType[],
): boolean => {
  const needleFloors = needle.map((floor) => floor.id);
  const haystackFloors = haystack.map((floor) => floor.id);

  return needleFloors.some((floor) => haystackFloors.includes(floor));
};
